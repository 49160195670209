const baseUrl = window.location.origin + "/api";

export function reportsPath(): string {
    return `${baseUrl}/reports`;
}

export function reportFilePath(filePath: string): string {
    return `${reportsPath()}/file?path=${filePath}`;
}

export function reportZipPath(): string {
    return `${reportsPath()}/zip`;
}