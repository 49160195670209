import React, {useMemo, useState} from "react";
import './Reports.css';
import {useAuth} from "react-oidc-context";
import {useToasts} from "../shared/Toasts";
import {downloadFile} from "../shared/downloadFile";
import {useFetch} from "../shared/useFetch";
import {reportFilePath, reportsPath, reportZipPath} from "../shared/api";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import {isFile, FileTree, buildTree, matchesFilter, sortTree, isDir} from "./fileTree";
import {useTranslation} from "react-i18next";

function depthStyle(depth: number) {
    return {paddingLeft: depth * 30};
}

/**
 * Compare function for {@link sortTree} that returns directories representing more
 * recent years first, e.g. 2024 before 2023, to ensure they most recent data is rendered on top.
 * Files inside the folders are sorted alphabetically.
 */
const sortMostRecentYearDirFirst = (a: FileTree, b: FileTree) => {
    if (isDir(a) && isDir(b)) {
        // reversed compare to ensure higher numbers are first, e.g. 2024 is alphabetically after 2023
        // by inverting the compare (b to a instead of a to b) we reverse that
        return b.name.localeCompare(a.name)
    }
    return a.name.localeCompare(b.name)
}

function File({fileTree, depth, filter}: { fileTree: FileTree, depth: number, filter: string }) {
    const auth = useAuth();
    const toasts = useToasts();
    const matches = matchesFilter(fileTree, filter);
    const {t} = useTranslation();

    return (
        <div className={`Subtree-line${matches ? '' : ' hidden'}`} title="Click to download" style={depthStyle(depth)}
             onClick={
                 () => downloadFile(reportFilePath(fileTree.fullPath), fileTree.name, auth.user!.access_token).catch(() => {
                     toasts.add({title: t('reports.download_failed_title'), text: t('reports.download_failed_text')})
                 })
             }>
            <div></div>
            <i className="bi bi-filetype-pdf Subtree-file-symbol"></i>
            <div className="Subtree-name">{fileTree.name}</div>
        </div>
    )
}

/**
 * Toggle state (open/closed) for an item that always respects explicit user interaction
 * but allows to use other logic to display an item as open/closed while the user has not yet interacted with it.
 */
enum ToggleState {
    /**
     * User never interacted with the item explicitly.
     * Other logic must be used to decide whether it is shown as open or closed.
     * If the item was shown as open and the user closed the item (e.g. by clicking) state should transition to TOGGLE_CLOSE
     * If the item was shown as closed and the user opened the item (e.g. by clicking) state should transition to TOGGLE_OPEN
     */
    NEVER_INTERACTED,
    /** User explicitly closed an open item to hide it, MUST be displayed as closed */
    TOGGLE_CLOSE,
    /** User explicitly opened a closed item to show it, MUST be displayed as open */
    TOGGLE_OPEN
}

function Dir({fileTree, depth, filter}: { fileTree: FileTree, depth: number, filter: string }) {
    const [toggleState, setToggleState] = useState(ToggleState.NEVER_INTERACTED);
    const matches = matchesFilter(fileTree, filter);

    const matchesNonEmptyFilter = filter && matches;
    // Open if:
    // - The user explicitly opened it once
    // - The user never interacted with it and the Dir matches the filter. As a result searching will visually open some folders.
    // This means by default (no filter set or not matching) the Dir is closed
    const open = toggleState === ToggleState.TOGGLE_OPEN ||
        (toggleState === ToggleState.NEVER_INTERACTED && matchesNonEmptyFilter);

    return (
        <div className={`Subtree-dir${matches ? '' : ' hidden'}`}>
            <div
                className={'Subtree-line'}
                onClick={() => setToggleState(open ? ToggleState.TOGGLE_CLOSE : ToggleState.TOGGLE_OPEN)}
                style={depthStyle(depth)}
            >
                <i className={open ? "bi bi-chevron-down Subtree-toggle-symbol" : "bi bi-chevron-right Subtree-toggle-symbol"}></i>
                <i className={open ? "bi bi-folder2-open Subtree-file-symbol" : "bi bi-folder Subtree-file-symbol"}></i>
                <div className="Subtree-name">{fileTree.name}</div>
            </div>
            {
                open ? fileTree.children.map(child =>
                    (<SubTree fileTree={child} depth={depth + 1} filter={filter} key={child.name}/>)
                ) : undefined
            }
        </div>
    );
}

function SubTree(props: { fileTree: FileTree, depth: number, filter: string }) {
    return isFile(props.fileTree) ? <File {...props} /> : <Dir {...props} />
}

function ZipDownloadButton() {
    const toasts = useToasts();
    const {t} = useTranslation();
    const auth = useAuth();
    const [isDownloading, setIsDownloading] = useState(false);

    return (
        <Button size="sm" disabled={isDownloading} onClick={
            () => {
                setIsDownloading(true);
                downloadFile(reportZipPath(), `reports-${new Date().toDateString()}.zip`, auth.user!.access_token)
                    .catch(() => {
                        toasts.add({
                            title: t('reports.download_failed_title'),
                            text: t('reports.download_failed_text')
                        })
                    }).finally(() => {
                        setIsDownloading(false);
                    });
            }
        }>
            {
                isDownloading
                    ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    : <i className="bi bi-cloud-download"></i>
            }
            {" "}{t('reports.download_zip')}
        </Button>
    )
}

export function Reports() {
    const {data, error} = useFetch<string[]>(reportsPath());
    const {t} = useTranslation();
    const [filter, setFilter] = useState("");
    const tree = useMemo(() => {
        const parsedTree = buildTree(data || []);
        return sortTree(parsedTree, sortMostRecentYearDirFirst)
    }, [data])
    const empty = tree.children.length === 0 || !matchesFilter(tree, filter);

    return (
        <section>
            <Row className="Reports-title py-3">
                <h2>{t('reports.title')}</h2>
                <div className="Reports-lead-Wrapper">
                    <p className="Reports-lead">
                        {t('reports.lead')}
                    </p>
                </div>
            </Row>

            <Row>
                <Col sm={4}>
                    <Form.Control size="sm" type="text" placeholder={t('reports.search')} value={filter}
                                  onChange={(e) => setFilter(e.target.value)}/>
                </Col>
                <Col sm={4}></Col>
                <Col sm={4} className="text-end">
                    <ZipDownloadButton />
                </Col>
            </Row>

            <Row className="py-3">
                {error && <Alert variant="warning">{t('reports.error')}</Alert>}
                {data && tree.children.map(child => (
                    <SubTree fileTree={child} depth={0} key={child.name} filter={filter}/>))}
                {empty && <em>{t('reports.no_match')}</em>}
            </Row>
        </section>
    );
}
