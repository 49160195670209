import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

/**
 * Supported languages as 2-letter iso code (keys) with native language name (values)
 */
export const SUPPORTED_LANGUAGES = {
    fr: 'Français',
    de: 'Deutsch',
    en: 'English'
}

// Good tutorial: https://locize.com/blog/react-i18next/
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            fr: {
                translation: {
                    layout: {
                        user_manual: "Guide d’utilisateur",
                        logout: "Déconnexion",
                        change_password: "Changer le mot de passe"
                    },
                    reports: {
                        title: 'Résultats de la dosimétrie individuelle',
                        lead: 'Téléchargez les résultats dosimétriques disponibles.',
                        error: 'Le chargement a échoué. Veuillez actualiser la page.',
                        no_match: 'Pas de résultat',
                        search: 'Recherche ...',
                        download_zip: 'Télécharger tous les dossiers (.zip)',
                        download_failed_title: 'Le téléchargement a échoué.',
                        download_failed_text: 'Le fichier n\'a pas pu être téléchargé.'
                    }
                }
            },
            en: {
                translation: {
                    layout: {
                        user_manual: "User manual",
                        logout: "Logout",
                        change_password: "Change Password"
                    },
                    reports: {
                        title: 'Reports',
                        lead: 'Download the individual monitoring data below.',
                        error: 'Failed to download reports. Try to refresh the page',
                        no_match: 'No match found',
                        search: 'Search reports...',
                        download_zip: 'Download all (.zip)',
                        download_failed_title: 'Download failed',
                        download_failed_text: 'Could not download the requested file.'
                    }
                }
            },
            de: {
                translation: {
                    layout: {
                        user_manual: "Benutzerhandbuch",
                        logout: "Abmelden",
                        change_password: "Passwort ändern"
                    },
                    reports: {
                        title: 'Resultate der Personendosimetrie',
                        lead: 'Laden Sie hier die aktualisierten Daten zur Personendosimetrie herunter.',
                        error: 'Laden der Resultat fehlgeschlagen. Versuchen sie die Seite zu aktualisieren.',
                        no_match: 'Kein Resultat',
                        search: 'Suche...',
                        download_zip: 'Alle herunterladen (.zip)',
                        click_to_download: 'Klicken zum herunterladen',
                        download_failed_title: 'Herunterladen fehlgeschlagen',
                        download_failed_text: 'Die Datei konnte nicht heruntergeladen werden.',
                    }
                }
            }
        }
    });

export default i18n;