import {Container, Navbar, NavDropdown, NavLink} from "react-bootstrap";
import React from "react";
import {useAuth} from "react-oidc-context";
import {AuthGuard} from "./AuthGuard";
import './Layout.css';
import {useTranslation} from "react-i18next";
import {SUPPORTED_LANGUAGES} from "../shared/i18n";
import {PASSWORD_CHANGE_URL} from "../shared/auth";

export function Layout({children}: { children: React.ReactNode }) {
    const auth = useAuth();
    const {i18n, t} = useTranslation();

    return (
        <div className="Layout">
            <Navbar expand="sm" bg="primary">
                <Container fluid className="justify-content-between">
                    <Navbar.Brand className="mr-auto"><img src="/logo_dosilab_neg_outline.svg"
                                                           alt="Dosilab Logo"/></Navbar.Brand>

                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <NavLink href="/dosi_info_resultats_dosimetriques.pdf" className="px-3">
                            <i className="bi bi-info-circle"></i>
                        </NavLink>

                        {auth.isAuthenticated &&
                            <NavDropdown title={<i className="bi bi-gear-fill"></i>}
                                         className="navbar-settings" drop="start">
                                <NavDropdown.Item
                                    onClick={() => auth.signoutRedirect({post_logout_redirect_uri: window.location.href})}>
                                    {t('layout.logout')}
                                </NavDropdown.Item>
                                <NavDropdown.Item href={PASSWORD_CHANGE_URL}>
                                    {t('layout.change_password')}
                                </NavDropdown.Item>
                                <NavDropdown.Divider/>
                                {Object.entries(SUPPORTED_LANGUAGES).map(([languageCode, languageNativeName]) => (
                                    <NavDropdown.Item key={languageCode}
                                                      onClick={() => i18n.changeLanguage(languageCode)}>
                                        {i18n.resolvedLanguage === languageCode ?
                                            <strong>{languageNativeName}</strong> : languageNativeName}
                                    </NavDropdown.Item>
                                ))}
                            </NavDropdown>
                        }
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container>
                <AuthGuard>
                    {children}
                </AuthGuard>
            </Container>
        </div>
    )
}