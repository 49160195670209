export interface FileTree {
    readonly children: FileTree[];
    readonly name: string;
    readonly fullPath: string;
}

const PATH_SEPARATOR = "/";

export const isFile = (fileTree: FileTree): boolean => {
    return fileTree.children.length === 0;
}

export const isDir = (fileTree: FileTree): boolean => {
    return !isFile(fileTree);
}

export const matchesFilter = (fileTree: FileTree, filter: string): boolean => {
    if(!filter) {
        return true;
    }

    if(isFile(fileTree)) {
        return fileTree.name.toLowerCase().includes(filter.toLowerCase());
    }

    return fileTree.children.some((child: FileTree) => matchesFilter(child, filter));
}

/**
 * Recursively sorts the children of each (sub) fileTree based on the passed compare function
 * @param fileTree the fileTree whose children should be sorted
 * @param compareFn same as for Array.sort(): Should return:
 * - a positive number if a > b, i.e. a should appear before b
 * - zero if a == n
 * - a negative number if a < b, i.e. b should appear before a
 * @return a sorted copy of the fileTree
 */
export const sortTree = (fileTree: FileTree, compareFn: (a: FileTree, b: FileTree) => number): FileTree => {
    if(isFile(fileTree)) {
        return fileTree;
    }

    return {
        ...fileTree,
        children: fileTree.children.map(subTree => sortTree(subTree, compareFn)).sort(compareFn)
    }
}

/**
 * Converts a list of unix like file path strings (e.g. ["2024/a.pdf", "2023/b.pdf"])
 * into a tree structure.
 * @param filePaths unix style file paths. The part after the last {@link PATH_SEPARATOR} is always considered
 * to be a file, all other levels directories.
 */
export const buildTree = (filePaths: string[]): FileTree => {
    const sortedFilePaths = filePaths.slice().sort();
    const root = {children: [] as FileTree[], name: "", fullPath: ""};
    sortedFilePaths.forEach((filePath) => {
        let parts = filePath.split(PATH_SEPARATOR);
        let parent = root;
        for (let part of parts) {
            let treeElement = parent.children.find(child => child.name === part);
            if (!treeElement) {
                treeElement = {
                    name: part,
                    children: [],
                    fullPath: `${parent.fullPath ? parent.fullPath + PATH_SEPARATOR : ""}${part}`
                }
                parent.children.push(treeElement);
            }
            parent = treeElement;
        }
    });

    return root;
}