import {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";

interface FetchResponse<T> {
    data: T | undefined;
    error: any;
    isLoading: boolean;
}
/**
 * GET data from an url requiring authorization.
 * Initially data is undefined, loading false and no error is set.
 * Each time the url changes a new GET request is issued; data, loading and error are reset.
 * until the fetch request is completed.
 * @param url the full url to GET data from
 * @return FetchResponse for the url
 */
export function useFetch<T>(url: string): FetchResponse<T> {
    const auth = useAuth();

    const [data, setData] = useState<T | undefined>(undefined);
    const [error, setError] = useState<any>(undefined);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        setLoading(true);
        setError(undefined);
        setData(undefined);

        fetch(url, {
            signal,
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        }).then(async (response) => {
            if (!response.ok) {
                throw new Error(`Request failed: Status code ${response.status}`);
            }
            const newData = await response.json();
            setData(newData);
            setLoading(false);
        }).catch((e) => {
            // Only update state if the error is a real error and not a cancellation
            if(e.name !== 'AbortError') {
                setLoading(false);
                setError(e);
            }
        });

        return () => controller.abort();
    // ignore the missing dependency on auth.user?.access_token
    // otherwise each silent access token refresh would reload everything
    // Instead this only depends on whether the user isAuthenticated, as
    // it should still reload if the user logs successfully.
    }, [url, auth.isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

    return {data, isLoading, error};
}